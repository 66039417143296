'use strict'

###*
 # @ngdoc object
 # @name mundoAdmin.dispatch.controller:DispatchUnitTypeCodesCtrl

 # @description

###
class DispatchUnitTypeCodesCtrl
  ### @ngInject ###
  constructor:(
    id
    $mdDialog
    DispatchUnitTypesManager
    $q
  ) ->
    @progress = true
    @model = {}

    init = =>
      @id = id
      @fields = DispatchUnitTypesManager.getCodeForm()

      @load = load
      @add = add
      @remove = remove
      @cancel = cancel
      @submit = submit

      @load()
    ##########

    add = ->
      @progress = true
      $q.when(DispatchUnitTypesManager.addCode(@id, @model))
        .then =>
          @load()
        , (resp) =>
          @errors = extractErrors(resp.data.errors.children)
          @progress = false

    remove = (codeId) ->
      @progress = true
      $q.when(DispatchUnitTypesManager.removeCode(@id, codeId))
        .then =>
          @load()
        , (resp) =>
          @errors = extractErrors(resp.data.errors.children)
          @progress = false

    load = ->
      console.log 'load'
      DispatchUnitTypesManager.getCodes(@id)
        .then (data) =>
          @items = data
          @progress = false
          @form = {}
          @errors = []

    cancel = ->
      $mdDialog.cancel()

    submit = ->
      console.log 'submit'

    extractErrors = (errorsArray) ->
      errors = []
      for key, value of errorsArray
        if value.errors?
          errors.push('key': key, 'value': value.errors)
      errors

    init()
angular
  .module('mundoAdmin.dispatch')
  .controller 'DispatchUnitTypeCodesCtrl', DispatchUnitTypeCodesCtrl
